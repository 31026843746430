<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item prop="title" label="标题">
            <a-input
              v-model="form.title"
              placeholder="请输入标题"
              style="width: 80%"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item prop="object" label="推送对象">
            <a-select
              v-model="form.object"
              placeholder="请选择"
              style="width: 80%"
            >
              <a-select-option
                v-for="item in options.object"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="status" label="状态">
            <a-select
              v-model="form.status"
              placeholder="请选择"
              style="width: 80%"
            >
              <a-select-option
                v-for="item in options.status"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</a-select-option
              >
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="content" label="内容">
            <a-textarea
              v-model="form.content"
              placeholder="请输入内容"
              style="width: 80%"
			  :autosize="{ minRows: 12}"
            ></a-textarea>
			<div>
                已输入 {{form.content.length}} / 1024 字
            </div>
          </a-form-model-item>
          封面图片
          <commonUpload
            :fileList="fileList"
            @handleChange="handleChange"
          ></commonUpload>
          附件
          <a-upload
            name="file"
            :multiple="true"
            :action="`${$upload}`"
            :file-list="documentList" 
            accept=".doc,.DOC,.xls,.XLS,.xlsx,.XLSX,.pdf,.PDF"
            :headers="uploadHeaders"
            @change="changeFile"
			:beforeUpload='beforeUpload'
          >
            <a-button> <a-icon type="upload" /> 上传附件</a-button>
          </a-upload>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import store from "@/store";
import { form, rules, options } from "./form.js";
import {
	announcementInsert,
	announcementUpdate,
	announcementInfo,
} from "@/api/operation/announcement";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增公告",
			form,
			rules,
			options,
			fileList: [],
			documentList: [],
			uploadHeaders: {
				"manage-login-token": store.getters.getToken,
			},
		};
	},
	methods: {
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.fileList = [];
			this.documentList = [];
			this.form.coverImgUrls = [];
			this.form.annexUrls = [];
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		beforeUpload(f, l) {
			const isLt50M = f.size / 1024 / 1024 < 50;
			if (!isLt50M) {
				this.$message.error(f.name + "文件大小超出限制，请修改后重新上传");
				return false;
			} else {
				return true;
			}
		},
		submit() {
			// 附件
			let arr = [];
			for (let k of this.documentList) {
				if (k.response) {
					arr.push(k.response.data);
				} else {
					arr.push(k.url.split("/")[k.url.split("/").length - 1]);
				}
			}
			this.form.annexUrls = arr;
			// 封面图
			let pic = [];
			for (let k of this.fileList) {
				if (k.response) {
					pic.push(k.response.data);
				} else {
					pic.push(k.url.split("/")[k.url.split("/").length - 1]);
				}
			}
			this.form.coverImgUrls = pic;
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await announcementInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await announcementUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
		changeFile(info) {
			this.documentList = info.fileList;
			if (info.file.status !== "uploading") {
				console.log(info.file, info.fileList);
			}
			if (info.file.status === "done") {
				this.$message.success(`${info.file.name} 上传成功`);
				// this.form.annexUrls.push(info.file.response.data);
			} else if (info.file.status === "error") {
				this.$message.error(`${info.file.name} 上传失败`);
			}
		},
		// handleChange(data) {
		// 	this.fileList = data;
		// 	this.form.coverImgUrls = [];
		// 	if (data[0].status === "done") {
		// 		console.log("-------done-------");
		// 		this.form.coverImgUrls.push(data[0].response.data);
		// 	}
		// },
		handleChange(data) {
			this.fileList = data;
			this.form.coverImgUrls = [];
			data.forEach(ele => {
				if(ele.status == 'done') {
					this.form.coverImgUrls.push(ele.response.data)
				}
			})
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.title = "修改公告";
					this.form.id = val;
					announcementInfo({ announcementId: val }).then((res) => {
						this.form = res.data;
						if (res.data.coverImgList.length > 0) {
							// console.log(res.data.coverImgList);
							// this.form.coverImgUrls.push(res.data.coverImgList[0].url)
							const pic = [];
							for (let item of res.data.coverImgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								pic.push(obj);
							}
							this.fileList = pic;
						} else {
							this.form.coverImgUrls = [];
						}
						if (res.data.annexImgList.length > 0) {
							const file = [];
							for (let item of res.data.annexImgList) {
								let obj = {
									name: item.url.split("_")[0] + "." + item.url.split(".")[1],
									url: this.$ImgUrl(item.url),
									uid: item.url.split("_")[1],
									status: "done",
									thumbUrl: this.$ImgUrl(item.url),
								};
								file.push(obj);
							}
							this.documentList = file;
						} else {
							this.form.annexUrls = [];
						}
					});
				} else {
					this.title = "新增公告";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
