export const formItem = [
	{
		type: 'input',
		label:'标题',
		prop:'title',
		placeholder:'请输入'
	},
	{
		type: 'select',
		label:'推送对象',
		prop:'object',
		option:[{ id:1,name:'全部'},{ id:2,name:'住户'},{ id:3,name:'业主'},{ id:4,name:'租户'},{ id:5,name:'管家'}],
		placeholder:'请选择'
	},
	{
		type: 'select',
		label:'发布状态',
		prop:'status',
		option:[{ id:1,name:'未发布'},{ id:2,name:'已发布'}],
		placeholder:'请选择'
	},
	{
		type: 'time',
		label: '更新时间',
		prop:'modify',
		start: 'modifyStartTime',
		end:'modifyEndTime'
	},
]
export const columns = [
	{
		title: "标题",
		dataIndex: "title",
		width: "21%",
	},
	{
		title: "推送对象",
		dataIndex: "object",
		width: "10%",
		customRender: function (object) {
			switch (object) {
			case 1:
				return '全部'
				break;
			case 2:
				return '住户'
				break;
			case 3:
				return '业主'
				break;
			case 4:
				return '租户'
				break;
			case 5:
				return '管家'
				break;
			default:
				break;
			}
		}
	},
	{
		title: "状态",
		dataIndex: "status",
		width: "8%",
		customRender: function (status) {
			switch (status) {
			case 1:
				return '未发布'
				break;
			case 2:
				return '已发布'
				break;
			default:
				break;
			}
		}
	},
	{
		title: "内容",
		dataIndex: "content",
		width: "21%",
		scopedSlots: { customRender: "content" },
	},
	{
		title: "阅读量",
		dataIndex: "readingVolume",
		width: "7%",
	},
	{
		title: "附件下载次数",
		dataIndex: "downloadNum",
		width: "7%",
	},
	{
		title: "更新时间",
		dataIndex: "modifyDate",
		width: "14%",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}