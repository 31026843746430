export const form = {
	id:undefined,
	title:undefined,
	object:undefined,
	status:undefined,
	content:'',
	coverImgUrls:[],
	annexUrls:[],
}
export const rules = {
	title:[{required:true,message:'请输入标题',trigger:'blur'}],
	object:[{required:true,message:'请选择',trigger:'change'}],
	status:[{required:true,message:'请选择',trigger:'change'}],
	content:[{required:true,message:'请输入内容',trigger:'blur'}],
}
export const options = {
	status:[
		{ id:1, name:'未发布' },
		{ id:2, name:'已发布' },
	],
	object:[
		{ id:1, name:'全部' },
		{ id:2, name:'住户' },
		{ id:3, name:'业主' },
		{ id:4, name:'租户' },
		{ id:5, name:'管家' },
	],
}