<template>
  <div>
    <div class="cardTitle">公告</div>
    <searchForm :formItem="formItem" @getSearch="search($event)" @changeTime='changeTime'></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="drawer.show = true"
      >新增公告</a-button
    >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      :scroll="{ x: 1900 }"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
	  <span slot="content" slot-scope="text, record">
		  <a-popover>
			<template slot="content">
				<div style="width: 600px">{{record.content}}</div>
			</template>
		  	<div>{{doStr(record.content,21)}}</div>
		  </a-popover>
	  </span>
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :editId="editId"
    ></addForm>
  </div>
</template>

<script>
import { announcementList, announcementDel } from "@/api/operation/announcement";
import { formItem, columns, pagination } from "./depend/config";
import addForm from "./depend/form.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			drawer: {
				show: false,
			},
			editId: null,
			tableData: [],
			searchForm: {
				title:undefined,
				status:undefined,
				object:undefined,
				modifyStartTime:undefined,
				modifyEndTime:undefined,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		search(data){
			this.searchForm = data;
			this.getData()
		},
		changeTime(data,start,end){
			this.searchForm[start] = data[0]
			this.searchForm[end] = data[1]
		},
		async getData() {
			let res = await announcementList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys=[]
		},
		edit(id) {
			this.editId = id;
			this.drawer.show = true;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await announcementDel({ activityOrganizerIds: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		// 选择，获取id
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		// 批量操作
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		// 跳转
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.drawer.show = false;
			this.editId = null;
		},
		success() {
			this.getData();
		},
		//截取内容
		doStr(str, number) {
			if(str.length >= number) {
				return str.substr(0,number-1) + '...' 
			} else {
				return str
			}
		}
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
</style>
